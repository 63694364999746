/* body {
    font-size: .875rem;
    background-color: aliceblue;
    padding-top: 40px;
  } */
  
  .feather {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
  }

  hr{
    color: gray;
    margin-top: 10px;
    
  }
  .custom-toggler.navbar-toggler {
    border-color: white;
    color: white;
}

  /*
   * Sidebar
   */
  
  .sidebar {
    position: fixed;
    top: 0;
    padding-top: 25px;
    padding-left: 10px;
    background-color: black;
    width: 240px;
    /* rtl:raw:
    right: 0;
    */
    bottom: 0;
    /* rtl:remove */
    left: 0;
     z-index: 100; /*Behind the navbar */
    /* padding: 48px 0 0; /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  }

  .location {
    top: 0px;
    z-index: 0;
  }
  
  @media (max-width: 767.98px) {
    .sidebar {
      position: relative;
      width: auto;
    }
    .location{
      top: 0px;
    }
  }
  
  .sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  }
  
  .sidebar .nav-link {
    font-weight: 600;
    color: #333;

  }

  .nav-link:hover{
    color:red;
    
  }
  
  .sidebar .nav-link .feather {
    margin-right: 4px;
    color: #727272;
  }
  
  .sidebar .nav-link.active {
    color: #dc2a24;
  }
  
  .sidebar .nav-link:hover .feather,
  .sidebar .nav-link.active .feather {
    color: inherit;
  }
  
  .sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase;
  }
  
  /*
   * Navbar
   */
  
  .span-text-link {
    margin-right: 5px;
    vertical-align:text-bottom;
  }
  
  .navbar-brand {
    color: black;
    text-align: justify;
    font-weight: bolder;
    font-size: large;
  }
  .navbar-brand:hover{
    color:red;
  }
  
  .navbar .form-control {
    padding: .75rem 1rem;
    border-width: 0;
    border-radius: 0;
  }
  
  .form-control-dark {
    color: #fff;
    background-color: rgba(255, 255, 255, .1);
    border-color: rgba(255, 255, 255, .1);
  }
  
  .form-control-dark:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
  }

  .bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

  @media (min-width: 768px) {
    .bd-placeholder-img-lg {
      font-size: 3.5rem;
    }
  }