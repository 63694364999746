.product-card{
    width: 18rem;
}
.item-card-text{
    color: black;
}
.item-card{
    margin-top: 7px;
    transition: all .2s ease-in-out;
    text-align: center;
}
.item-card:hover{
    transform: scale(1.02);
}
.item-card-img{
    height: 7rem;
    width: 7rem;
    background-color: gray;
    object-fit: cover;
    margin: 2px;
    border-radius: 50%;
}
.icons{
    color: red;
    margin: 5px;
}
.icons:hover{
    color: white;
    background-color: red;
    border: 3px;
    padding: 5px;

}