.location-card{
    margin: 5px;
    padding: 10px;
    border-radius: 10px;
    transition: all .2s ease-in-out;
    text-align: center;
}
.location-card:hover{
    transform: scale(1.02);
}
.location-text{
    color:black;
}
.location-menu-title{
    padding-bottom: 15px;
}
.menu-items{
    color: gray;
    outline-color: red;
}
.hr-bottom {
    color: red;
}
