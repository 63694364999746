.tag{
  color: gray;
  background-color: white;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}
.menu-tag-hr{
  color: gray;
  margin: 0px;
  padding: 0px;
}
.menu-tag-text{
  color: white;
  position: relative;
  top: 40%;
  font-weight: bold;
}
.cat-banner-section{
  text-align: center;
  vertical-align: middle;
  margin-top: 15px;
  margin-bottom: 30px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.icon{
  padding: 5px;  
  margin-right: 8px;
  position: relative;
  top: 5px;
}
.icon-div{
    text-align: right;
}
.cat-title-img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
