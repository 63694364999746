
.pricing{
  background-color:antiquewhite;
}

.card {
  min-width: 250px;
}
  
.card-deck {
  min-width: 300px;
}
