/* .menu-container{
    text-align: center;
    color: grey;
    background-color: #faebd7;
    padding: 2rem;
    min-height: 100vh;
    font-weight: bold;
    font-family: 'Krona One', sans-serif;
} */
.offers-carousel{
    height: 50vh;
}
.carousel-img{
    height: 50vh;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.menu-item-img{
    height: 180px;
    width: 90%;
    padding: 0;
    margin: 0;
    object-fit: cover;
    border-radius: 4px;
}

@media screen and (max-width: 768px){
    .menu-item-img{
        height: 100px;
        width: 100%;
    }
}