.buttons {
    text-align: center;
}
.image-preview{
    align-items: center;
    text-align: center;
    height: 160px;
    width: 440px;
    border-radius: 10px;
    padding-bottom: 5px;
    object-fit: cover;
}
.p-img-prev{
    color:black;
}
.image-upload{
    width: 440px;
}