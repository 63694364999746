.main-signin {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 100vh;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: antiquewhite;
}

.form-signin {
  width: 100%;
  max-width: 350px;
  padding: 15px;
  margin: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* Add the blur effect */
  /* backdrop-filter: blur(80px);
  border-radius: 15px; */
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 10;
  border-top-left-radius: 10;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 10;
  border-bottom-right-radius: 10;
}

.form-signin input[type="text"] {
  margin-bottom: -1px;
  border-top-left-radius: 10;
  border-top-right-radius: 10;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.link-text{
  color: aliceblue;
  font-weight: 800;
}

.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

  @media (min-width: 768px) {
    .bd-placeholder-img-lg {
      font-size: 3.5rem;
    }
  }