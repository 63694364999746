.nav-container{
    background-color: #151D3B;
    width: 100%;
    padding: 5px 5rem 5px 5rem;
    color: white;
    text-align: center;
}
.nav-link {
    color: white !important;
}
.btn-custom{
    border-color: white;
    color: white;
    margin-left: 10px;
    border-radius: 20px;
}
.btn-custom:hover{
    background-color: white;
    color:#151D3B;
}
.nav-link:hover{
    color: #D82148 !important;
}