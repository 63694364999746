
.main-section-2{
  z-index: -3;
  height: 100vh;
  padding: -1rem;
  margin: 0;
}

.hero-img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.video-frame{
  height: 280px;
  width: 500px;
  margin-top: 1rem;
  border-radius: 5px;
}


.main-img{
  height: 90%;
  width: 90%;
  border-radius: 10px;
  object-fit: cover;
}
.features{
  padding: 0;
  background-color: #ffebcd;
}
.feature-title{
  text-align: center;

}
.card-title{
  text-transform: uppercase;
  color: gray;
  font-weight: bold;
}
.feature-card{
  padding: 0;
  border-radius: 10px;
  text-align: center;
  color: gray;
  
}

.form-section{
  background: #fff;
  background: linear-gradient(180deg, #fff 50%, #ffebcd 90%);  
}

.contact-form{
  width: 90%;
  margin-bottom: 4rem;
}


.footer{
  text-align: center;
  vertical-align: bottom;
}


@media (width < 768px) {
  .video-frame{
    width: 350px;
    height: 220px;
    padding-top: 0;
  }
}

@media (width < 1200px) {
  .video-frame{
    width: 350px;
    height: 220px;
    margin-top: 2rem;
  }
}

@media (min-width: 768px) {
  .contact-form {
    width: 50%;
  }
  .main-section-1 {
    margin-bottom: 5rem;
  }
  .main{
    padding-top: 5rem;
  }
}